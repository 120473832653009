<template>
  <div class="box p-5 rounded-md">
    <div
      class="flex items-center justify-between border-b border-slate-200 border-opacity-60 pb-5 mb-5"
    >
      <div class="font-medium text-base truncate">
        <app-i18n code="auction.view.bids"></app-i18n>
      </div>
    </div>
    <div class="overflow-auto -mt-3 relative">
      <loading-spinner
        type="orbit"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <table class="w-full text-left">
        <thead class="bg-slate-200/60">
          <tr>
            <th
              v-for="col in columns"
              :key="col.name"
              class="font-medium px-5 py-3 border-b-2 whitespace-nowrap text-center"
            >
              <app-i18n :code="col.label"></app-i18n>
            </th>
          </tr>
        </thead>
        <tbody>
          <transition-group name="listAnimate">
            <tr
              class="odd:bg-white even:bg-slate-100"
              v-for="bid in record"
              :key="bid.id"
            >
              <td class="text-center px-5 py-3 border-b">
                {{ presenter(bid, 'createdBy') }}
              </td>
              <td class="text-center px-5 py-3 border-b">
                {{ presenter(bid, 'amount') }}
              </td>
              <td class="text-center px-5 py-3 border-b">
                <router-link
                  :to="`/users/${bid.createdBy}`"
                  class="whitespace-nowrap cursor-pointer truncate font-semibold underline hover:text-theme-10"
                >
                  {{ presenter(bid, 'bidderName') }}
                </router-link>
              </td>
              <td class="text-center px-5 py-3 border-b">
                {{
                  `${presenterTimestamp(
                    bid,
                    'createdAt',
                    'time'
                  )} ${presenterTimestamp(bid, 'createdAt')}`
                }}
              </td>
            </tr></transition-group
          >
        </tbody>
      </table>
      <app-empty-page
        v-if="!record.length && !loading"
        :label="i18n('empty.bids')"
        label-class="text-lg font-medium"
        icon-size="4x"
        class="box w-full py-5"
      ></app-empty-page>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  data() {
    return {
      columns: [
        {
          name: 'id',
          field: 'id',
          label: 'auction.fields.bidderID',
          type: 'string',
          align: 'center'
        },
        {
          name: 'amount',
          field: 'amount',
          label: 'auction.fields.amount',
          type: 'string',
          align: 'center'
        },
        {
          name: 'bidder',
          field: 'bidder',
          label: 'auction.fields.bidder',
          type: 'string',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'auction.fields.createdAt',
          type: 'date',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'auction/view/bidsRecord',
      loading: 'auction/view/findLoading'
    })
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterTimestamp(row, fieldName, type) {
      console.log(fieldName, row)
      return GenericModel.presenterTimestamp(row, fieldName, type)
    }
  }
}
</script>
<style>
.listAnimate-move,
.listAnimate-enter-active,
.listAnimate-leave-active {
  transition: all 0.8s ease;
}
.listAnimate-enter-from {
  opacity: 0;
  transform: translateX(-500px);
}
.listAnimate-leave-to {
  opacity: 0;
  transform: translateX(500px);
}
.listAnimate-move {
  transition: all 0.8s ease;
}
.listAnimate-leave-active {
  position: absolute;
}
</style>
