<template>
  <div class="box p-5 rounded-md">
    <div class="flex items-center border-b border-slate-200/60  pb-5 mb-5">
      <div class="font-medium text-base truncate">
        <app-i18n code="auction.view.details"></app-i18n>
      </div>
    </div>
    <div class="relative">
      <loading-spinner
        type="orbit"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <div
        class="flex items-center mt-3 gap-2"
        v-for="detail in details"
        :key="detail.label"
      >
        <!-- <Component :is="detail.icon" class="w-4 h-4 text-slate-500" /> -->
        <app-i18n :code="detail.label" />:
        <span class="font-medium text-primary" v-if="detail.type === 'date'">{{
          presenterTimestamp(record, detail.field)
        }}</span>
        <span
          class="font-medium text-primary"
          v-else-if="detail.field === 'highestBidder'"
          >{{ record[detail.field]?.fullName || '_' }}</span
        >
        <span class="font-medium text-primary" v-else>{{
          presenter(record, detail.field)
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'

export default {
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'auction/view/auctionRecord',
      loading: 'auction/view/findLoading'
    })
  },
  data() {
    return {
      details: [
        {
          label: 'auction.fields.auctionNo',
          field: 'auctionNo',
          icon: 'BuildingIcon'
        },
        {
          label: 'auction.fields.currentBid',
          field: 'currentBid',
          icon: 'clipboardIcon'
        },
        {
          label: 'auction.fields.numOfBids',
          field: 'numOfBids',
          icon: 'UserIcon'
        },
        {
          label: 'auction.fields.minimumBid',
          field: 'minimumBid',
          icon: 'PhoneIcon'
        },
        {
          label: 'auction.fields.minBidIncrement',
          field: 'minBidIncrement',
          icon: 'atSignIcon'
        },
        {
          label: 'auction.fields.maxBidIncrement',
          field: 'maxBidIncrement',
          icon: 'atSignIcon'
        },
        {
          label: 'auction.fields.minTargetPrice',
          field: 'minTargetPrice',
          icon: 'atSignIcon'
        },
        {
          label: 'auction.fields.maxTargetPrice',
          field: 'maxTargetPrice',
          icon: 'atSignIcon'
        },
        {
          label: 'auction.fields.auctionDuration',
          field: 'auctionDuration',
          icon: 'CalendarIcon'
        },
        {
          label: 'auction.fields.startDate',
          type: 'date',
          field: 'startDate',
          icon: 'CalendarIcon'
        },
        {
          label: 'auction.fields.endDate',
          type: 'date',
          field: 'endDate',
          icon: 'CalendarIcon'
        },
        {
          label: 'auction.fields.status',
          field: 'status',
          icon: 'CalendarIcon'
        },
        {
          label: 'auction.fields.highestBidder',
          field: 'highestBidder',
          icon: 'CalendarIcon'
        }
      ]
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTimestamp(row, fieldName) {
      return GenericModel.presenterTimestamp(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    }
  }
}
</script>
