<template>
  <div class="box p-5 rounded-md">
    <div
      class="flex items-center justify-between border-b border-slate-200 border-opacity-60 pb-5 mb-5"
    >
      <div class="font-medium text-base truncate">
        <app-i18n code="auction.view.items"></app-i18n>
      </div>
      <button class="btn btn-secondary" @click="addItemToAuction">
        <app-i18n code="auction.view.addItem" />
      </button>
    </div>
    <div class="overflow-auto -mt-3 relative">
      <loading-spinner
        type="orbit"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <table class="w-full text-left">
        <thead class="bg-slate-200/60">
          <tr>
            <th
              v-for="col in columns"
              :key="col.name"
              class="font-medium px-5 py-3 border-b-2 whitespace-nowrap text-center"
            >
              <app-i18n :code="col.label" />
            </th>
          </tr>
        </thead>
        <tbody>
          <transition-group name="listAnimate">
            <tr class="odd:bg-white even:bg-slate-100" v-if="showInputs">
              <td class="text-center px-5 py-3 border-b" colspan="3">
                <div>
                  <div class="form-inline mt-5 items-start first:mt-0">
                    <div class="flex-1">
                      <div
                        class="grid grid-cols-12 items-center mt-5 first:mt-0"
                        v-for="(item, idx) in items"
                        :key="idx"
                      >
                        <div class="input-group flex-1 col-span-10">
                          <input
                            id="crud-form-1"
                            type="text"
                            class="form-control w-full"
                            v-model="item.value"
                            :placeholder="i18n('auction.fields.addItemId')"
                          />
                        </div>
                        <div
                          class="flex items-center justify-center text-slate-500 col-span-1"
                          @click.prevent="addItem"
                        >
                          <PlusSquareIcon class="w-5 h-5 cursor-pointer" />
                        </div>
                        <div
                          class="flex items-center justify-center text-slate-500 col-span-1"
                          @click.prevent="deleteItem(idx)"
                        >
                          <Trash2Icon class="w-5 h-5 cursor-pointer" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center px-5 py-3 border-b">
                <a
                  href="javascript:;"
                  @click="saveItems"
                  class="text-secondary whitespace-nowrap flex items-center justify-center gap-1 hover:bg-gray-200 p-2  rounded-md"
                >
                  <XCircleIcon
                    class="w-4 h-4"
                    v-if="items[0].value.length === 0"
                  />
                  <saveIcon class="w-4 h-4" v-else />
                  <app-i18n
                    :code="
                      items[0].value.length === 0
                        ? 'common.cancel'
                        : 'common.save'
                    "
                  />
                </a>
              </td>
            </tr>
            <tr
              class="odd:bg-white even:bg-slate-100"
              v-for="item in record"
              :key="item.id"
            >
              <td class="text-center px-5 py-3 border-b">
                <div class="flex items-center justify-center gap-4">
                  <div class="w-10 h-10 image-fit zoom-in rounded-full">
                    <Tippy
                      tag="img"
                      data-action="zoom"
                      alt="Midone - HTML Admin Template"
                      class="rounded-lg border-2 border-white shadow-md tooltip"
                      :src="item.thumbnail"
                    />
                  </div>
                </div>
              </td>
              <td class="text-center px-5 py-3 border-b">
                {{ presenter(item, 'title') }}
              </td>
              <td class="text-center px-5 py-3 border-b">
                {{ presenter(item, 'sku') }}
              </td>
              <td class="text-center px-5 py-3 border-b">
                <a
                  href="javascript:;"
                  data-toggle="modal"
                  data-target="#delete-modal-preview"
                  class="text-secondary whitespace-nowrap flex items-center justify-center gap-1 hover:bg-gray-200 p-2  rounded-md"
                  @click="selectedId = item.id"
                  ><Trash2Icon class="w-4 h-4 mr-1 ml-1" />{{
                    i18n('common.delete')
                  }}</a
                >
              </td>
            </tr>
          </transition-group>
        </tbody>
      </table>
      <app-empty-page
        v-if="!record.length && !loading && !showInputs"
        :label="i18n('empty.items')"
        label-class="text-lg font-medium"
        icon-size="4x"
        class="box w-full py-5"
      ></app-empty-page>
    </div>
  </div>
  <!-- BEGIN: Delete Modal -->
  <DeleteModal item="item" @delete="doDelete" />
  <!-- END: Delete Modal -->
</template>
<script>
import { GenericModel } from '@/shared/model/generic-model'
import { mapActions, mapGetters } from 'vuex'
import DeleteModal from '@/components/modals/delete-modal.vue'

export default {
  components: {
    DeleteModal
  },
  props: {
    auctionId: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      items: [{ value: '' }],
      showInputs: false,
      selectedId: null,
      columns: [
        {
          name: 'thumbnail',
          field: 'thumbnail',
          label: 'auction.fields.thumbnail',
          type: 'string',
          align: 'center'
        },
        {
          name: 'title',
          field: 'title',
          label: 'auction.fields.title',
          type: 'string',
          align: 'center'
        },
        {
          name: 'sku',
          field: 'sku',
          label: 'auction.fields.sku',
          type: 'date',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'auction/view/itemsRecord',
      saveLoading: 'auction/view/saveLoading',
      findLoading: 'auction/view/findLoading'
    }),
    loading() {
      return this.saveLoading || this.findLoading
    }
  },
  methods: {
    ...mapActions({
      doAddItems: 'auction/view/doAddItems',
      doDeleteItem: 'auction/view/doDeleteItem'
    }),
    async doDelete() {
      await this.doDeleteItem({
        auctionId: this.auctionId,
        itemsIds: [this.selectedId]
      })
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    addItemToAuction() {
      this.items = [{ value: '' }]
      this.showInputs = true
    },
    async saveItems() {
      this.showInputs = false
      if (this.items.length > 1 || this.items[0].value.length !== 0) {
        await this.doAddItems({
          auctionId: this.auctionId,
          itemsIds: this.items.map(item => item.value)
        })
      }
    },
    addItem() {
      this.items.push({ value: '' })
    },
    deleteItem(idx) {
      if (this.items.length === 1) {
        this.items[0].value = ''
        return
      }
      this.items.splice(idx, 1)
    }
  },
  async mounted() {}
}
</script>
<style>
.listAnimate-move,
.listAnimate-enter-active,
.listAnimate-leave-active {
  transition: all 0.8s ease;
}
.listAnimate-enter-from {
  opacity: 0;
  transform: translateX(-500px);
}
.listAnimate-leave-to {
  opacity: 0;
  transform: translateX(500px);
}
.listAnimate-move {
  transition: all 0.8s ease;
}
.listAnimate-leave-active {
  position: absolute;
}
</style>
