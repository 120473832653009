<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium">
        <app-i18n code="auction.view.title" />
      </h2>
    </div>
    <div
      style="width: 100%; height: 80vh"
      class="intro-y box overflow-hidden mt-5 flex justify-center"
      v-if="loading"
    >
      <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
    </div>
    <div class="intro-y grid grid-cols-11 gap-5 mt-5" v-if="record && !loading">
      <div class="col-span-12 lg:col-span-4 2xl:col-span-3 flex flex-col gap-5">
        <AuctionDetails />
      </div>
      <div class="col-span-12 lg:col-span-7 2xl:col-span-8 flex flex-col gap-5">
        <AuctionBids />
        <AuctionItems :auctionId="id" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AuctionDetails from '@/views/auction/auction-details.vue'
import AuctionBids from '@/views/auction/auction-bids.vue'
import AuctionItems from '@/views/auction/auction-items.vue'

export default {
  props: {
    id: {
      type: String,
      required: false
    }
  },
  components: {
    AuctionDetails,
    AuctionBids,
    AuctionItems
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'auction/view/auctionRecord',
      loading: 'auction/view/findLoading'
    })
  },
  async mounted() {
    await this.doFind(this.id)
  },
  methods: {
    ...mapActions({
      doFind: 'auction/view/doFindWithListener'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.auction')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.auction')
    }
  }
}
</script>
